@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10%);
    }
  }
  
  .dropdown-enter {
    animation: slideDown 0.5s forwards;
  }
  
  .dropdown-exit {
    animation: slideUp 0.5s forwards;
  }
  
  